<script>

	import LogoActive from "./LogoActive.svelte";

</script>

<div class="absolute top-1/2 left-1/2" style="transform:translate(-50%, -50%); ">

    <LogoActive icon_class_override="w-16 h-16" animate={true} />
    
    <div class="text-primary-foreground-enabled mt-6 font-medium place-content-center flex w-full -mr-4 text-xs whitespace-nowrap">
        Diving in...
    </div>
</div>
<!-- 
<div class="circle bg-backdrop-light">
    <div class="wave bg-primary flex place-items-center place-content-center">
      <img src="/logo/swimerize-icon-light-bg.svg" alt="logo" class="w-14 h-14 animate-squeeze" style="animation-iteration-count: infinite;"/>
    </div>
</div> 
-->
<!-- 
<div class="absolute top-[60%] w-full">
  <div class="text-primary-foreground-enabled mt-1 font-semibold place-content-center flex">
    Loading...
  </div>
</div> 
-->

<!-- 
<style>
  .circle{
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%,-50%);
    width: 100px;
    height: 100px;
    background:#ccc;
    border:5px solid #fff;
    box-shadow:0 0 0 5px #c025d3;
    border-radius:50%;
    overflow:hidden;
  }
  .wave{
    position:relative;
    width:100%;
    height:100%;
    /* background:#4973ff; */
    border-radius:50%;
    box-shadow:inset 0 0 50px rgba(0,0,0,.5);
  }
  .wave:before,.wave:after{
    content:'';
    position:absolute;
    width:200%;
    height:200%;
    top:0;
    left:50%;
    transform:translate(-50%,-75%);
    background:#000;
  }
  .wave:before{
    border-radius:45%;
    background:rgba(255,255,255,1);
    animation:animate 5s linear infinite;
  }
  .wave:after{
    border-radius:40%;
    background:rgba(255,255,255,.5);
    animation:animate 10s linear infinite;
  }
  @keyframes animate{
    0%{
      transform:translate(-50%,-75%) rotate(0deg);
    }
    100%{
      transform:translate(-50%,-75%) rotate(360deg);
    }
  }
</style> 
-->
